import clsx from "clsx";

export function TypographyH3(props: { className?: string; children: any }) {
  return (
    <h3
      className={clsx(
        "scroll-m-20 text-lg font-semibold tracking-tight",
        props.className
      )}
    >
      {props.children}
    </h3>
  );
}
